import React from 'react';
import styled from 'styled-components';
import Form from '../components/Styles/Form';
import Grid from '../components/Styles/Grid';
import Button from '../components/Buttons/Button';

function ContactForm() {
  return (
    <FormStyles
      id="contact-form"
      method="POST"
      netlify-honeypot="bot-field"
      data-netlify="true"
    >
      <fieldset>
        <input type="hidden" name="form-name" value="contact-form" />
        <div className="input-group input-group--honeypot">
          <label>
            Don’t fill this out if you’re human:
            <input name="bot-field" />
          </label>
        </div>
      </fieldset>

      <fieldset>
        <Grid
          gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr']}
          gridGap={4}
          mb="1.5em"
        >
          <div className="input-group">
            <label htmlFor="name">
              Name<span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="text"
              aria-label="text input"
              name="name"
              placeholder="Your Name"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">
              Email<span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="text"
              aria-label="text input"
              name="email"
              placeholder="Your Email"
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="phone">Phone Number</label>
            <input
              type="tel"
              aria-label="phone number"
              name="phone"
              placeholder="Phone Number"
            />
          </div>
          <div className="input-group">
            <label htmlFor="company">Company</label>
            <input
              type="text"
              aria-label="text input"
              name="company"
              placeholder="Your Company"
            />
          </div>
          <div className="input-group">
            <label htmlFor="website">Website URL</label>
            <input
              type="text"
              aria-label="text input"
              name="website"
              placeholder="https://yourwebsite.com"
            />
          </div>
          <div className="input-group">
            <label htmlFor="budget">Project Budget</label>
            <input
              type="text"
              aria-label="text input"
              name="budget"
              placeholder="$4000"
            />
          </div>
        </Grid>
        <div className="input-group">
          <label htmlFor="message">Additional Info...or just say hi :)</label>
          <textarea name="message" />
        </div>
        <Button>Request your Estimate</Button>
      </fieldset>
    </FormStyles>
  );
}

const FormStyles = styled(Form)`
  fieldset {
    padding: 0;
  }
  label {
    font-family: Barlow;
    font-style: normal;
    font-weight: bold;
    font-size: 25.888px;
    line-height: 31px;
    color: var(--seafoam);
  }
  ${Button} {
    margin-top: 1.5em;
    background: var(--seafoam);
  }
  input {
    height: 85px;
    background: #fafafa;
    border: 1px solid #e7e7e7;
    box-sizing: border-box;
    border-radius: 2px;
  }
  @media (min-width: 960px) {
    margin-right: 25%;
    label {
      font-family: Barlow;
      font-style: normal;
      font-weight: bold;
      font-size: 25.888px;
      line-height: 31px;
      color: var(--seafoam);
    }
    input {
      height: 85px;
      background: #fafafa;
      border: 1px solid #e7e7e7;
      box-sizing: border-box;
      border-radius: 2px;
    }
  }
`;

export default ContactForm;
