import React, { Component } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Section from '../components/Section';
import Box from '../components/Styles/Box';
import PageHeader from '../components/PageHeader';
import codeLines from '../images/code-lines-compact.svg';
import SingleLineTextWithIcon from '../components/SingleLineTextWithIcon';
import ContactForm from '../components/ContactForm';
import ContactStack from '../components/ContactStack';

class ContactPage extends Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO title="Contact Us" />
        <PageHeaderStyled
          heading="Contact Us"
          subHeading="To get in touch with us please use one of the methods below. If you want to get started on a project request a quote. Since 2016 we have been building custom responsive websites for our clients to help their business grow, let us help you too."
          arrow={false}
        />
        <SectionPurple bottomDivider={true}>
          <ContactForm />
          <ContactStack />
        </SectionPurple>
      </Layout>
    );
  }
}
const PageHeaderStyled = styled(PageHeader)`
  min-height: 25vh;
  background: var(--darkPurple);
  color: white;
  padding-bottom: 0;
`;

const SectionPurple = styled(Section)`
  background: var(--darkPurple);
  color: var(--light);
  overflow: hidden;
  :after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    opacity: 0.75;
    top: 65%;
    z-index: -1;
    width: 759px;
    height: 741px;
    background: url(${codeLines}) no-repeat;
  }
  @media (min-width: 960px) {
    :after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      opacity: 0.25;
      top: 65%;
      z-index: -1;
      width: 759px;
      height: 741px;
      background: url(${codeLines}) no-repeat;
    }
    :after {
      left: 65%;
      top: 0;
      z-index: 1;
    }
  }
`;

export default ContactPage;
