import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Typography from './Styles/Typography';
import SingleLineTextWithIcon from '../components/SingleLineTextWithIcon';

function ContactStack() {
  const query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          phoneNumber
          address {
            company
            street
            town
            state
            zipcode
          }
        }
      }
    }
  `);
  return (
    <Wrap>
      <Typography as="span" className="preHeading">
        Phone
      </Typography>
      <SingleLineTextWithIconStyles
        className="font-big"
        text="(978) 592-2118‬ "
      />
      <Typography as="span" className="preHeading">
        Email
      </Typography>
      <SingleLineTextWithIconStyles
        className="font-big"
        text="info@alternativedev.com"
      />
      <SingleLineTextWithIconStyles
        className="font-big"
        text="support@alternativedev.com"
      />
      <SingleLineTextWithIconStyles
        className="font-big"
        text="billing@alternativedev.com"
      />
      <Typography as="span" className="preHeading">
        Address
      </Typography>
      <address className="font-big">
        {query.site.siteMetadata.address.street}, <br />
        {`${query.site.siteMetadata.address.town}, ${query.site.siteMetadata.address.state} ${query.site.siteMetadata.address.zipcode}`}
      </address>
    </Wrap>
  );
}
const Wrap = styled.div`
  margin-top: 2em;
  span.preHeading {
    color: var(--light);
    font-weight: 400;
  }
  .font-big {
    font-size: 1.8em;
    font-weight: bold;
    font-family: Barlow;
  }
  @media (min-width: 960px) {
    .font-big {
      font-size: 2.2em;
    }
  }
`;

const SingleLineTextWithIconStyles = styled(SingleLineTextWithIcon)``;

export default ContactStack;
